import { createSelector } from 'reselect';
import { FORCE_NEW_ADMIN } from 'config';
import { isUserAdmin } from 'services/auth/selectors/common';
import { getQuery } from 'services/app-router/selectors/common';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'services/utils';
import { ModeTypes, TargetTypes, ActionKey } from './constants';
import IState from 'services/state';
import last from 'lodash/last';
import {
  ADMIN_BAR_WIDTH_SM_PX,
} from 'style/constants';
import { IObject } from 'models';
import { ID_SIDEBAR } from 'services/renderer/ids';
import isEmpty from 'lodash/isEmpty';
import { FooterLink, Social, Sponsor } from 'models/IFooter';
import { getIsSimpleModeEnabled } from 'services/feature-gate/selectors';
import { FORTNITE_LEADERBOARD_BLOCK_PANEL_ID } from 'components/objects/PanelV2/constants';

export const getNewAdminAllowed = (state: IState): boolean => state.app.site?.settings?.allowNewAdmin || false;
export const allowNewAdmin = (state: IState): boolean =>
  Boolean(isUserAdmin(state) && (FORCE_NEW_ADMIN || getNewAdminAllowed(state)));
export const isDesignMode = (state: IState) => allowNewAdmin(state) && state.admin.adminMode === ModeTypes.DESIGN;
export const isEditMode = (state: IState) => allowNewAdmin(state) && state.admin.adminMode === ModeTypes.EDIT;
export const isBroadcastMode = (state: IState) => allowNewAdmin(state) && state.admin.adminMode === ModeTypes.BROADCAST;
export const isMaestroStreamMode = (state: IState) =>
  allowNewAdmin(state) && state.admin.adminMode === ModeTypes.MAESTRO_STREAM;
export const isStudioStreamMode = (state: IState) =>
  allowNewAdmin(state) && state.admin.adminMode === ModeTypes.STUDIO_STREAM;

export const isStreamMode = createSelector([
  isMaestroStreamMode, isStudioStreamMode,
], (maestroStream, studioStream) =>
  (maestroStream || studioStream),
);

export const isStreamMethodSelectMode = (state: IState) =>
  allowNewAdmin(state) && state.admin.adminMode === ModeTypes.STREAM_METHOD_SELECT;
export const isInPageSettings = (state: IState) =>
  allowNewAdmin(state) && state.admin.activeAction === ActionKey.pageOrChannelSettings;

export const isAccessControlMode = (state: IState) =>
  allowNewAdmin(state) && state.admin.adminMode === ModeTypes.ACCESS_CONTROL;

// isEditing wraps every admin mode that have the draft system (auto-save / discard / publish)
export const isEditing = createSelector(
  [isDesignMode, isEditMode],
  (isDesignAdminMode, isEditAdminMode) => {
    return isDesignAdminMode || isEditAdminMode;
  },
);

export const isAccessControlFTUE = createSelector(
  [(state: IState) => state.auth.account?.tags],
  (tags) => {
    if (!tags) return true;
    const accessFTUETag = tags.find(tag => /access-ftue:/.test(tag));
    return !accessFTUETag;
  },
);

export const getPanelsActive = (state: IState) => state.admin.panelsActive;
export const getEditTarget = (state: IState) => (allowNewAdmin(state) ? state.admin.editModeTarget : null);

export const getPendingPageDocs = createSelector(
  (state: IState) => state.admin?.pendingPageDocs,
  (pendingPageDocs) => pendingPageDocs,
);

export const getPendingThemesDocs = (state: IState) => state.admin.pendingThemesDocs;

export const getPendingAdminChanges = createSelector(
  (state: IState) => state.admin?.pendingAdminChanges,
  (pendingAdminChanges) => pendingAdminChanges || EMPTY_OBJECT,
);
export const getRegionRendererDrafts = createSelector(
  (state: IState) => state.admin?.regionRendererDrafts,
  (regionDrafts) => regionDrafts,
);
export const getCardIdDraft = createSelector(
  getRegionRendererDrafts,
  (regionDrafts) => {
    return regionDrafts[ID_SIDEBAR]?.cardId;
  },
);

export const getPanelDrafts = createSelector(
  getRegionRendererDrafts,
  (regionDrafts) => {
    return regionDrafts[ID_SIDEBAR]?.items;
  },
);

export const hasUnpublishedAdminChanges = (state: IState) => {
  const pendingChanges = getPendingAdminChanges(state);
  const hasPendingLegacyData = Object.values(pendingChanges).some((item) => item.pendingData);
  const hasRegionDrafts = Boolean(Object.keys(state.admin.regionRendererDrafts).length);
  const hasPageDrafts = Boolean(getPendingPageDoc(state));
  const hasPendingGateDrafts = Boolean(getPendingGateChange(state));
  const hasPendingPanelDrafts = !isEmpty(getPendingPanelsChanges(state));
  const hasThemesDrafts = Boolean(Object.keys(getPendingThemesDocs(state)).length);

  return Boolean(
    hasPendingLegacyData ||
    hasRegionDrafts ||
    hasPageDrafts ||
    hasThemesDrafts ||
    hasPendingGateDrafts ||
    hasPendingPanelDrafts,
  );
};

export const isEditingGridRegion = (state: IState) => {
  return isEditMode(state) && getEditTarget(state) === TargetTypes.LANDING;
};

export const isEditTargetSponsors = (state: IState) => getEditTarget(state) === TargetTypes.SPONSORS;

export const getUnpublishedSponsorChanges = (state: IState): Record<string, any> => (
  state.admin.pendingAdminChanges?.[TargetTypes.SPONSORS] || {}
);

export const getPendingPageDoc = createSelector(
  [(state) => state.app.object?._id, getPendingPageDocs],
  (id, pendingDocs) => (pendingDocs ? pendingDocs[id] as IObject : null),
);

export const getPendingLandingPageContent = createSelector(
  getPendingPageDoc,
  (obj) => obj?.data?.landingContent,
);

export const getLandingPageContent = createSelector(
  [isEditMode, getPendingLandingPageContent, (state: IState) => state.app.object?.data?.landingContent || []],
  (editing, pending, server) => editing ? pending || server : server,
);

export const getPendingThemesDoc = createSelector(
  [(state: IState) => state.admin.mockThemeRenderer?._id!, getPendingThemesDocs],
  (id, pendingDocs) => (pendingDocs ? pendingDocs[id] : null),
);

export const getPendingRegionsData = createSelector(
  getPendingPageDoc,
  (pendingDoc) => pendingDoc?.data?.regions || null,
);
export const getEditingPageDoc = createSelector(
  [getPendingPageDoc, (state) => state.app.object as IObject],
  (pendingDoc, currentDoc) => (pendingDoc ? pendingDoc : currentDoc),
);

export const getPendingPageNav = createSelector(
  [(state: IState) => state.admin.pendingAdminChanges?.navigation?.pendingData],
  (navDoc) => navDoc ? navDoc : null,
);

export const getPendingOrActualObject = createSelector(
  [
    isEditMode,
    getIsSimpleModeEnabled,
    isUserAdmin,
    (state: IState) => state.app?.object,
    getPendingPageDoc,
  ],
  (editing, simpleModeEnabled, isAdmin, currentDoc, pendingDoc) => {
    if (simpleModeEnabled) {
      if (!isAdmin) {
        return currentDoc;
      }

      return pendingDoc || currentDoc;
    }
    return editing ? pendingDoc || currentDoc : currentDoc;
  },
);

export const getPendingOrActualLogoLink = createSelector(
  [getPendingOrActualObject],
  (doc) => {
    return doc?.data?.artwork?.header?.logoLink;
  },
);

export const getPendingOrActualLogoTitle = createSelector(
  [getPendingOrActualObject],
  (doc) => {
    return doc?.data?.artwork?.header?.logoTitle;
  },
);

export const getPendingOrActualLogoUrl = createSelector(
  [getPendingOrActualObject],
  (doc) => {
    return doc?.data?.artwork?.header?.logo;
  },
);

export const getPendingOrActualTweetMessage = createSelector(
  [
    (state: IState) => state?.app?.site?.regions?.footer?.tweetMessage,
    getPendingOrActualObject,
  ],
  (siteTweetMessage, doc) => {
    const legacyChannelTweetMessage = doc?.renderers?.socials?.tweetMessage;
    const channelTweetMessage = doc?.data?.regions?.footer?.tweetMessage;
    return channelTweetMessage || legacyChannelTweetMessage || siteTweetMessage || '';
  },
);

export const getPendingOrActualHashtag = createSelector(
  [
    (state: IState) => state.app?.site?.regions?.footer?.hashtag,
    getPendingOrActualObject,
  ],
  (siteHashtag, doc) => {
    const channelLegacyHashtag = doc?.renderers?.socials?.hashtag;
    const channelHashtag = doc?.data?.regions?.footer?.hashtag;

    return [channelHashtag, channelLegacyHashtag, siteHashtag, ''].find((hashtag) => hashtag);
  },
);

export const getPendingOrActualSocialsLinks = createSelector(
  [
    (state: IState) => state.app?.site?.regions?.footer?.socials,
    getPendingOrActualObject,
  ],
  (siteSocialLinks, doc) => {
    const channelSocials = Array.isArray(doc?.data?.regions?.footer?.socials)
      ? doc?.data?.regions?.footer?.socials as Social[] : [];

    const legacyChannelSocial = doc.renderers?.socials;
    const legacyChannelSocialLinks = doc.renderers?.socials?.links;

    if (channelSocials || legacyChannelSocial) {
      return (
        channelSocials ||
        legacyChannelSocialLinks ||
        EMPTY_ARRAY
      );
    }
    return (siteSocialLinks || EMPTY_ARRAY) as Social[];
  },
);

export const getPendingOrActualFooterSponsors = createSelector(
  [
    (state: IState) => state.app?.site?.regions?.footer?.sponsors,
    getPendingOrActualObject,
  ],
  (siteSponsors, doc) => {
    const channelSponsors = doc?.data?.regions?.footer?.sponsors;
    const sponsors = channelSponsors || siteSponsors || EMPTY_ARRAY;
    return sponsors.slice(0, 8) as Sponsor[];
  },
);

export const getPendingOrActualFooterLinks = createSelector(
  [
    (state: IState) => state.app?.site?.regions?.footer?.footerLinks,
    getPendingOrActualObject,
  ],
  (siteFooterLinks, doc) => {
    const channelFooterLinks = Array.isArray(doc?.data?.regions?.footer?.footerLinks)
      ? doc?.data?.regions?.footer?.footerLinks as FooterLink[] : [];

    const siteLinks = Array.isArray(siteFooterLinks)
      ? siteFooterLinks as FooterLink[] : [];

    return channelFooterLinks || siteLinks || EMPTY_ARRAY;
  },
);

export const getPendingOrActualChannelSelect = createSelector(
  [
    (state: IState) => state.app?.site?.regions?.['channel-select'],
    getPendingOrActualObject,
  ],
  (sitePages, doc) => {
    return doc?.data?.regions?.['channel-select'] || sitePages;
  },
);

export const getPendingOrActualChannelSelectPages = createSelector(
  [getPendingOrActualChannelSelect],
  (channelSelect) => channelSelect?.pages || EMPTY_ARRAY,
);

export const getPendingOrActualChannelSelectEnabled = createSelector(
  [getPendingOrActualChannelSelect],
  (channelSelect) => channelSelect?.state === 'on',
);

export const getPendingOrActualLandingPageContent = createSelector(
  [getPendingOrActualObject],
  doc => {
    return doc?.data?.landingContent || [];
  },
);

export const getPendingPageLogo = createSelector(
  [(state: IState) => getPendingPageDoc(state)?.data.artwork?.header],
  (logo) => {
    return logo ? {
      logoTitle: logo.logoTitle,
      logoUrl: logo.logo,
      logoLink: logo.logoLink,
    } : null;
  },
);

export const getPendingPageCountdown = createSelector(
  [(state: IState) => getPendingPageDoc(state)?.data.regions?.header?.countdown],
  (countdown) => countdown,
);

export const getPendingPageLanding = createSelector(
  [(state: IState) => getPendingPageDoc(state)?.data.landingContent],
  (landingContent) => landingContent || null,
);

export const getPendingPageRenderers = createSelector(
  [(state: IState) => getPendingPageDoc(state)?.renderers],
  (renderers) => {
    return renderers ? renderers : null;
  },
);

export const getPendingLegacyPageSocials = createSelector(
  [(state: IState) => state.admin.pendingAdminChanges],
  (pendingAdminChanges) => pendingAdminChanges?.social.pendingData,
);

export const getPendingPageSocials = createSelector(
  [(state: IState) => getPendingRegionsData(state)?.footer],
  (pendingFooter) =>
    pendingFooter
      ? {
        hashtag: pendingFooter.hashtag,
        socials: pendingFooter.socials,
        tweetMessage: pendingFooter.tweetMessage,
      } : null,
);

export const getPendingPageSocialLinks = createSelector(
  [(state: IState) => getPendingPageDoc(state)?.renderers?.socials?.links],
  (links) => links,
);

export const getPendingPageHashtag = createSelector(
  [(state: IState) => getPendingPageDoc(state)?.data.regions?.footer?.hashtag],
  (hashtag) => hashtag,
);

export const getPendingDefaultThemeId = createSelector(
  [(state: IState) => getPendingAdminChanges(state)[TargetTypes.DEFAULT_THEME_ID]?.pendingData],
  (pendingData) => pendingData as string,
);

export const getPendingSiteNavDoc = createSelector(
  [(state: IState) => getPendingAdminChanges(state)[TargetTypes.NAVIGATION]?.pendingData],
  (pendingData) => pendingData,
);

export const getPendingSiteSocialLinks = createSelector(
  [(state: IState) => getPendingAdminChanges(state)[TargetTypes.SOCIAL]?.pendingData?.socials],
  (socials) => socials,
);

export const getRefreshKey = (state: IState) => state.admin.refreshKey;

export const parseAdminQuery = createSelector([
  allowNewAdmin,
  (state: IState) => getQuery(state),
  ], (admin, query) => admin ? query : EMPTY_OBJECT,
);

export const getPendingOfflineContent = createSelector(
  [(state: IState) => getPendingPageDoc(state)?.data.content?.offline],
  (offlineContent) => offlineContent || null,
);
export const getSettingsDefaultThemeId = createSelector(
  (state: IState) => {
    const pendingDefaultThemeId = getPendingDefaultThemeId(state);
    return pendingDefaultThemeId || state.app.site?.settings.defaultThemeId;
  },
  (defaultThemeId) => defaultThemeId,
);

export const isViewingEarnings = (state: IState) => parseAdminQuery(state).admin === 'earnings';
export const isViewingInsights = (state: IState) => parseAdminQuery(state).admin === 'analytics-insights';
export const isViewingAnalyticsVideos = (state: IState) => parseAdminQuery(state).admin === 'analytics-videos';
export const isViewingAnalyticsInsights = (state: IState) => parseAdminQuery(state).admin === 'analytics-insights';
export const isManagingCommunity = (state: IState) => parseAdminQuery(state).admin === 'community';
export const isManagingPages = (state: IState) => parseAdminQuery(state).admin === 'pages';
export const isManagingAchievements = (state: IState) => parseAdminQuery(state).admin === 'achievements';
export const getManagingPageId = createSelector([parseAdminQuery], (query) => query.page as string);
export const getManagingPageType = (state: IState) => (parseAdminQuery(state).pageType as string) || 'channel';

export const getPreviewCardRenderer = (state: IState) => state.admin.previewCardRenderer;
export const getPreviewActiveTheme = (state: IState) => state.admin.previewActiveTheme;
export const getPreviewPanelRenderer = (state: IState) => state.admin.previewPanelRenderer;

export const getIsPreviewingFortniteLeaderboardV2 = createSelector(
  getPreviewPanelRenderer, (previewPanelRenderer) => previewPanelRenderer?.panelType === FORTNITE_LEADERBOARD_BLOCK_PANEL_ID,
);

export const regionHasPendingChanges = (state: IState, region: TargetTypes) => {
  return allowNewAdmin(state) && Boolean(state.admin.pendingAdminChanges?.[region]?.pendingData);
};

export const getSidebarDraft = createSelector(
  (state: IState) => getPendingPageDoc(state)?.renderers?.sidebar,
  (sidebarDraft) => sidebarDraft,
);

// TODO: A/B testing?
export const getDraftHomeId = (state: IState): string => {
  return state.admin.pendingAdminChanges?.[TargetTypes.HOME_ID]?.pendingData || state.app.site.settings?.homeId;
};

export const getPendingData = createSelector(
  (state: IState, regionTarget: TargetTypes) => {
    const pendingSiteChanges = getPendingAdminChanges(state);
    const pendingChannelSocials = getPendingPageSocials(state);
    const pendingPageNav = getPendingPageNav(state);
    const pendingRegions = getPendingRegionsData(state);
    const pendingPageLogo = getPendingPageLogo(state);
    const pendingCountdown = getPendingPageCountdown(state);
    const pendingLandingContent = getPendingPageLanding(state);

    if (
      regionTarget === TargetTypes.NAVIGATION &&
      (pendingPageNav || pendingRegions?.navigation)
    ) {
      return pendingPageNav || pendingRegions?.navigation;
    }

    if (regionTarget === TargetTypes.SOCIAL && pendingChannelSocials) {
      return pendingSiteChanges[regionTarget]?.pendingData || pendingChannelSocials;
    }

    if (regionTarget === TargetTypes.SPONSORS && pendingRegions?.footer?.sponsors) {
      return pendingSiteChanges[regionTarget]?.pendingData || pendingRegions?.footer?.sponsors;
    }

    if (regionTarget === TargetTypes.FOOTER_LINKS && pendingRegions?.footer?.footerLinks) {
      return pendingSiteChanges[regionTarget]?.pendingData || pendingRegions?.footer?.footerLinks;
    }

    if (regionTarget === TargetTypes.CHANNEL_NAVIGATION && pendingRegions?.['channel-select']) {
      return pendingSiteChanges[regionTarget]?.pendingData || pendingRegions?.['channel-select'];
    }

    if (regionTarget === TargetTypes.LOGO && pendingPageLogo) {
      return pendingSiteChanges[regionTarget]?.pendingData || pendingPageLogo;
    }

    if (regionTarget === TargetTypes.COUNTDOWN && pendingCountdown) {
      return pendingSiteChanges[regionTarget]?.pendingData || pendingCountdown;
    }

    if (regionTarget === TargetTypes.LANDING && pendingLandingContent) {
      return pendingSiteChanges[regionTarget]?.pendingData || pendingLandingContent;
    }
    return pendingSiteChanges[regionTarget]?.pendingData || null;
  },
  (pendingData) => pendingData,
);

export const getLocalData = createSelector(
  (state: IState, regionTarget: TargetTypes) => state.admin.pendingAdminChanges?.[regionTarget]?.localData,
  (localData) => localData,
);

export const getMockPanelRenderer = createSelector(
  (state: IState) => state.admin.mockPanelRenderer,
  (mockPanelRenderer) => mockPanelRenderer,
);
export const getMockCardRenderer = (state: IState) => state.admin.mockCardRenderer;
export const getMockThemeRenderer = (state: IState) => state.admin.mockThemeRenderer;

export const getPageBackground = createSelector(
  (artwork: { video: { background: any } }) => artwork?.video?.background || null,
  (background) => background,
);

export const isLivestreamPreviewActive = (state: IState) => state.admin.livestreamPreviewActive;
export const getAdminBarWidth = createSelector(
  [isUserAdmin],
  (isAdmin) => {
    if (isAdmin) {
      return ADMIN_BAR_WIDTH_SM_PX;
    }
    return 0;
  });
export const getHasAdminModal = (state: IState) => !!state.admin?.modalCount;
export const getActiveAction = createSelector(
  (state: IState): ActionKey | undefined => state.admin?.activeAction ?? undefined,
  (activeAction) => activeAction,
);

export const inAccessMenu = createSelector(
  getActiveAction,
  (activeAction) => activeAction === ActionKey.access,
);

export const inContentMenu = createSelector(
  getActiveAction,
  (activeAction) => (
    activeAction === ActionKey.content ||
    activeAction === ActionKey.pageOrChannelSettings
  ),
);

export const isAccessControlModeActive = createSelector(
  [isEditing, inAccessMenu],
  (isEditingMode, isInAccessMenu) => isEditingMode && isInAccessMenu,
);

export const getAdminBarSubMenuQueue = (state: IState) => state.admin.adminBarSubMenuQueue;

export const getCurrentSubMenuKey = createSelector(
  getAdminBarSubMenuQueue,
  (subMenuQueue) => last(subMenuQueue),
);

export const getActiveActionType = (state: IState) => state.admin.activeType;

export const getCurrentSidebarPage = (state: IState) => state.admin.currentSidebarPage;

export const getTheme = (state: IState) => state.admin.previewActiveTheme || state.themes.theme;

export const isCreatingPageOrChannel = (state: IState) => state.admin.isCreatingPageOrChannel;

export const isCreatingPanel = (state: IState) => state.admin.isCreatingPanel;

export const getCreatingKindPanel = (state: IState) => state.admin.creatingKindPanel;

export const getCreatingPanelAction = (state: IState) => state.admin.creatingPanelAction;

/** Pending Pages */
export const getPendingPage = createSelector(
  [(state: IState) => state.app.object._id, getPendingPageDocs],
  (objId, pendingPages) => pendingPages[objId] || null,
);

export const hasPageChanges = createSelector(
  getPendingPage,
  (pendingPage) => Boolean(pendingPage),
);


export const getEditingGate = createSelector(
  getEditingPageDoc,
  (toEditPage) => toEditPage.data.gate,
);

export const getIsEditingGateLogo = (state: IState) => state.admin.isEditingGateLogo;

export const getPendingGateChanges = (state: IState) => state.admin.pendingGateChanges;

export const getPendingGateChange = createSelector(
  [(state: IState) => state.app.object._id, getPendingGateChanges],
  (pageId, pendingGateChanges) => pendingGateChanges?.[pageId] || null,
);

export const getPendingPanelsChanges = createSelector(
  [(state: IState) => state.admin.pendingPanelChanges],
  (pendingPanelChanges) => pendingPanelChanges,
);

export const getPendingPagePanelsChanges = createSelector(
  [(state: IState) => state.app.object?._id, getPendingPanelsChanges],
  (pageId, pendingPanelChanges) => pendingPanelChanges?.[pageId] || null,
);

export const getChannelOfflineImage = createSelector([
  getPendingPageDoc,
  isEditing,
  (state: IState) => state.app.object,
], (pending, editing, current) => {
  if (!editing) {
    return current.data.content?.offline?.image;
  }

  const doc = pending || current;

  return doc.data.content?.offline?.image;
});

export const getAdminBarNotifications = createSelector(
  (state: IState) => state.admin.adminBarNotifications,
  (adminBarNotifications) => adminBarNotifications,
);

export const getAdminBarNotificationByType = createSelector(
  (state: IState, notificationType: string) => state.admin.adminBarNotifications[notificationType],
  (adminBarNotification) => adminBarNotification,
);
