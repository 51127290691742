import ISite from 'models/ISite';
import { makeAction } from 'redux-utils';

export interface ITriggerPageChangeAction {
  payload: any;
  type: typeof TRIGGER_PAGE_CHANGE;
}

export declare interface ISetSiteAction {
  payload: ISite;
  type: typeof SET_SITE;
}

export type MobileNavTypes = 'searchMenu' | 'loginMenu' | 'profileMenu';

export const SET_OBJECT = 'app/SET_OBJECT';
export const SET_SITE = 'app/SET_SITE';
export const TRIGGER_PAGE_CHANGE = 'app/TRIGGER_PAGE_CHANGE';
export const SET_TIME_OFFSET = 'app/SET_TIME_OFFSET';
export const SET_ACTIVE_PANEL = 'app/SET_ACTIVE_PANEL';
export const FIND_QUEST_PANEL = 'app/FIND_QUEST_PANEL';
export const ADD_PARENT_DOMAIN = 'app/ADD_PARENT_DOMAIN';
export const SET_SHOW_MOBILE_CHANNEL_SELECTOR = 'app/SET_SHOW_MOBILE_CHANNEL_SELECTOR';
export const TOGGLE_MOBILE_CHANNEL_SELECTOR = 'app/TOGGLE_MOBILE_CHANNEL_SELECTOR';
export const SET_CLOSE_CHANNEL_SELECT_ON_OUTSIDE_CLICK = 'app/SET_CLOSE_CHANNEL_SELECT_ON_OUTSIDE_CLICK';
export const SET_DISPLAY_MOBILE_NAV_MENU = 'app/SET_DISPLAY_MOBILE_NAV_MENU';

export const addParentDomain = makeAction(
  ADD_PARENT_DOMAIN,
  (domain: string) => ({ payload: { domain } }),
);
export const setObject = makeAction(
  SET_OBJECT,
  (object: Record<string, any>, loaded: boolean) => ({ payload: { loaded, object } }),
);
export const setSite = makeAction(
  SET_SITE,
  (payload: ISite) => ({ payload }),
);
export const setTimeOffset = makeAction(
  SET_TIME_OFFSET,
  (payload: number) => ({ payload }),
);
export const setActivePanel = makeAction(
  SET_ACTIVE_PANEL,
  (payload: string) => ({ payload }),
);
export const findQuestPanel = makeAction(
  FIND_QUEST_PANEL,
  (payload: object) => ({ payload }),
);
export const triggerPageChange = makeAction(
  TRIGGER_PAGE_CHANGE,
);

export const setShowMobileChannelSelector = makeAction(
  SET_SHOW_MOBILE_CHANNEL_SELECTOR,
  (payload: boolean) => ({ payload }),
);
export const toggleMobileChannelSelector = makeAction(
  TOGGLE_MOBILE_CHANNEL_SELECTOR,
);
export const setCloseChannelSelectOnOutsideClick = makeAction(
  SET_CLOSE_CHANNEL_SELECT_ON_OUTSIDE_CLICK,
);

export const setDisplayMobileNavMenu = makeAction(
  SET_DISPLAY_MOBILE_NAV_MENU,
  (payload: MobileNavTypes | null) => ({ payload }),
);
