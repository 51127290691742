import { TargetTypes, SettingsTypes, ActionKey } from './constants';
import { makeAction } from 'redux-utils';
import { SubMenuKey, ActionType } from 'components/admin-bridge/AdminBar/constants';
import { ITheme, IObject } from 'models';
import IGate from 'models/IGate';
import IPanel from 'models/IPanel';
import INavigation from 'models/INavigation';
import { NotificationDetails } from './reducer';

export const ADD_ADMIN_MODAL = 'admin/ADD_ADMIN_MODAL';
export const CLEAR_EDIT_TARGET = 'admin/CLEAR_EDIT_TARGET';
export const CLEAR_PENDING_THEMES_DOCS = 'admin/CLEAR_PENDING_THEMES_DOCS';
export const CLEAR_PENDING_PAGE_DOC = 'admin/CLEAR_PENDING_PAGE_DOC';
export const CLEAR_PENDING_GATE_CHANGE = 'admin/CLEAR_PENDING_GATE_CHANGE';
export const CLEAR_PENDING_PANEL_CHANGE = 'admin/CLEAR_PENDING_PANEL_CHANGE';
export const CLEAR_REGION_RENDERER_DRAFT = 'admin/CLEAR_REGION_RENDERER_DRAFT';
export const CONFIRM_CROSS_CHANNEL_VALIDATION_MODAL = 'admin/CONFIRM_CROSS_CHANNEL_VALIDATION_MODAL';
export const DISABLE_PANELS = 'admin/DISABLE_PANELS';
export const EDIT_COLLECTION_ITEM = 'admin/EDIT_COLLECTION_ITEM';
export const ENABLE_PANELS = 'admin/ENABLE_PANELS';
export const MAESTRO_GO_LIVE = 'admin/MAESTRO_GO_LIVE';
export const MAESTRO_GO_OFFLINE = 'admin/MAESTRO_GO_OFFLINE';
export const POP_ADMIN_MODAL = 'admin/POP_ADMIN_MODAL';
export const PUBLISH_PENDING_CHANGES = 'admin/PUBLISH_PENDING_CHANGES';
export const PUBLISH_GATE_STATE = 'admin/PUBLISH_GATE_STATE';
export const PUBLISH_PENDING_GATE_CHANGES = 'admin/PUBLISH_PENDING_GATE_CHANGES';
export const PUBLISH_PENDING_PANEL_CHANGES = 'admin/PUBLISH_PENDING_PANEL_CHANGES';
export const RESET_ALL_PENDING_ADMIN_CHANGES = 'admin/RESET_ALL_PENDING_ADMIN_CHANGES';
export const RESET_PENDING_ADMIN_CHANGE = 'admin/RESET_PENDING_ADMIN_CHANGE';
export const SAVE_QUEST = 'admin/SAVE_QUEST';
export const SAVE_SITE_SETTINGS = 'admin/SAVE_SITE_SETTINGS';
export const SET_ADMIN_MODE = 'admin/SET_ADMIN_MODE';
export const SET_DRAFT_THEME = 'admin/SET_DRAFT_THEME';
export const SET_EDIT_TARGET = 'admin/SET_EDIT_TARGET';
export const SET_HAS_UNSAVED_CHANGES = 'admin/SET_HAS_UNSAVED_CHANGES';
export const SET_MAESTRO_LIVESTREAM_PREVIEW = 'admin/SET_MAESTRO_LIVESTREAM_PREVIEW';
export const SET_MOCK_CARD_RENDERER = 'admin/SET_MOCK_CARD_RENDERER';
export const SET_MOCK_PANEL_RENDERER = 'admin/SET_MOCK_PANEL_RENDERER';
export const SET_PENDING_ADMIN_CHANGE = 'admin/SET_PENDING_ADMIN_CHANGE';
export const SET_PENDING_THEME_DOC = 'admin/SET_PENDING_THEME_DOC';
export const SET_PENDING_PANEL_CHANGE = 'admin/SET_PENDING_PANEL_CHANGE';
export const SET_PREVIEW_CARD_RENDERER = 'admin/SET_PREVIEW_CARD_RENDERER';
export const SET_PREVIEW_ACTIVE_THEME = 'admin/SET_PREVIEW_ACTIVE_THEME';
export const SET_PREVIEW_PANEL_RENDERER = 'admin/SET_PREVIEW_PANEL_RENDERER';
export const SET_REGION_RENDERER_DRAFT = 'admin/SET_REGION_RENDERER_DRAFT';
export const SET_REGION_SAVED = 'admin/SET_REGION_SAVED';
export const SET_HOME_PAGE = 'admin/SET_HOME_PAGE';
export const UPDATE_REFRESH_KEY = 'admin/UPDATE_REFRESH_KEY';
export const WRITE_LEGACY_SUCCESS = 'admin/WRITE_LEGACY_SUCCESS';
export const SET_ACCESS_CONTROL_FTUE = 'admin/SET_ACCESS_CONTROL_FTUE';
export const WRITE_TO_COLLECTION = 'admin/WRITE_TO_COLLECTION';
export const ARCHIVE_PAGE = 'admin/ARCHIVE_PAGE';
export const SET_ACTIVE_ACTION = 'admin/SET_ACTIVE_ACTION';
export const SET_ADMIN_BAR_SUB_MENU_QUEUE = 'admin/SET_ADMIN_BAR_SUB_MENU_QUEUE';
export const PUSH_ADMIN_BAR_SUB_MENU_KEY = 'admin/PUSH_ADMIN_BAR_SUB_MENU_KEY';
export const POP_ADMIN_BAR_SUB_MENU_KEY = 'admin/POP_ADMIN_BAR_SUB_MENU_KEY';
export const NAVIGATE_TO_ADMIN_BAR_ACTION = 'admin/NAVIGATE_TO_ADMIN_BAR_ACTION';
export const ADMIN_BAR_NAVIGATION = 'admin/ADMIN_BAR_NAVIGATION';
export const CLOSE_PANELS = 'admin/CLOSE_PANELS';
export const OPEN_BILLING_TAB = 'admin/OPEN_BILLING_TAB';
export const SET_ACTIVE_ACTION_TYPE = 'admin/SET_ACTIVE_ACTION_TYPE';
export const SET_CURRENT_SIDEBAR_PAGE = 'admin/SET_CURRENT_SIDEBAR_PAGE';
export const SET_IS_EDITING_GATE_LOGO = 'admin/SET_IS_EDITING_GATE_LOGO';

// SAVE/PUBLISH SYSTEM
export const SET_PENDING_PAGE_DOC = 'admin/SET_PENDING_PAGE_DOC';

export const SET_GATE_TITLE_CHANGES = 'admin/SET_GATE_TITLE_CHANGES';
export const SET_GATE_SUBTITLE_CHANGES = 'admin/SET_GATE_SUBTITLE_CHANGES';
export const SET_GATE_DATE_AND_TIME = 'admin/SET_GATE_DATE_AND_TIME';
export const SET_GATE_NAVIGATION = 'admin/SET_GATE_NAVIGATION';
export const SET_GATE_LOGO = 'admin/SET_GATE_LOGO';
export const SET_GATE_BACKGROUND = 'admin/SET_GATE_BACKGROUND';
export const SET_GATE_KIND_INFORMATION = 'admin/SET_GATE_KIND_INFORMATION';

// CREATE CHANNEL FLOW
export const CREATE_CHANNEL_OR_PAGE = 'admin/CREATE_CHANNEL_OR_PAGE';
export const CREATE_CHANNEL_OR_PAGE_SUCCESS = 'admin/CREATE_CHANNEL_OR_PAGE_SUCCESS';
export const CREATE_CHANNEL_OR_PAGE_ERROR = 'admin/CREATE_CHANNEL_OR_PAGE_ERROR';

// CREATE NEW PANELS
export const CREATE_PANEL = 'admin/CREATE_PANEL';
export const CREATE_PANEL_SUCCESS = 'admin/CREATE_PANEL_SUCCESS';
export const CREATE_PANEL_ERROR = 'admin/CREATE_PANEL_ERROR';

// DELETE PANEL
export const DELETE_PANEL = 'admin/DELETE_PANEL';

// ADMIN BAR NOTIFICATIONS
export const SET_ADMIN_BAR_NOTIFICATIONS = 'admin/SET_ADMIN_BAR_NOTIFICATIONS';
export const DISPLAY_ADMIN_BAR_NOTIFICATIONS_ALERT = 'admin/DISPLAY_ADMIN_BAR_NOTIFICATIONS_ALERT';
export const DISPLAY_ADMIN_BAR_NUMERIC_NOTIFICATION = 'admin/DISPLAY_ADMIN_BAR_NUMERIC_NOTIFICATION';
export const CLEAR_ADMIN_BAR_NOTIFICATION = 'admin/CLEAR_ADMIN_BAR_NOTIFICATION';

type Doc = Record<string, any>;

export interface IPublishPendingChangesPayload {
  pageId?: string | null;
  pendingAdminChanges?: Record<string, Doc>;
  pendingPageDocs?: Record<string, Doc>;
  regionRendererDrafts?: Record<string, Doc>;
}

export interface IPublishGatePayload {
  updatedPage: Doc,
}

export interface ICreateChannelOrPagePayload {
  newChannelOrPage: IObject,
  path?: string,
  updatedDefaultNavigation: INavigation,
}

export interface IGenericAdminBarNotificationPayload {
  type: ActionKey;
}

export interface ISetAdminBarNotificationsPayload extends IGenericAdminBarNotificationPayload {
  notification: NotificationDetails;
}

export interface IDisplayAdminBarNumericNotificationPayload extends IGenericAdminBarNotificationPayload {
  count: number;
}

export interface ITrackAdminNavigationData {
  element: string
  menu?: SubMenuKey | 'channel_select',
}

export const adminBarNavigation = makeAction(
  ADMIN_BAR_NAVIGATION,
  (payload: { activeAction: ActionKey, adminBarSubMenuQueue: SubMenuKey[] }) => ({ payload }),
);

export const setHomePage = makeAction(
  SET_HOME_PAGE,
  (payload: { pageId: string }) => ({ payload }),
);

export const setActiveActionType = makeAction(
  SET_ACTIVE_ACTION_TYPE,
  (actionType?: ActionType) => ({
    payload: actionType,
  }),
);

export const setAdminBarSubMenuQueue = makeAction(
  SET_ADMIN_BAR_SUB_MENU_QUEUE,
  (subMenuQueue: SubMenuKey[]) => ({
    payload: subMenuQueue,
  }),
);

export const pushAdminBarSubMenuKey = makeAction(
  PUSH_ADMIN_BAR_SUB_MENU_KEY,
  (subMenuKey: SubMenuKey) => ({
    payload: subMenuKey,
  }),
);

export const navigateToAdminBarAction = makeAction(
  NAVIGATE_TO_ADMIN_BAR_ACTION,
  (payload: { actionKey: ActionKey, replace?: boolean }) => ({
    payload,
  }),
);

export const closePanels = makeAction(CLOSE_PANELS);

export const popAdminBarSubMenuKey = makeAction(
  POP_ADMIN_BAR_SUB_MENU_KEY,
  (subMenuKey: SubMenuKey) => ({
    payload: subMenuKey,
  }),
);

export const openBillingTab = makeAction(OPEN_BILLING_TAB);

export const setActiveAction = makeAction(
  SET_ACTIVE_ACTION,
  (activeAction: ActionKey | null) => ({
    payload: activeAction,
  }),
);

export const setHasUnsavedChanges = makeAction(
  SET_HAS_UNSAVED_CHANGES,
  (hasUnsavedChanges: boolean) => ({
    payload: { hasUnsavedChanges },
  }),
);

export const addAdminModal = makeAction(ADD_ADMIN_MODAL);

export const popAdminModal = makeAction(POP_ADMIN_MODAL);

export const enablePanels = makeAction(
  ENABLE_PANELS,
  (isStudioLive?: boolean) => ({
    payload: { isStudioLive },
  }),
);

export const disablePanels = makeAction(DISABLE_PANELS);

export const setAdminMode = makeAction(
  SET_ADMIN_MODE,
  (modeName: string | null) => ({
    payload: { modeName },
  }),
);

export const setEditTarget = makeAction(
  SET_EDIT_TARGET,
  (targetName: string) => ({
    payload: { targetName },
  }),
);

export const clearEditTarget = () => ({
  type: CLEAR_EDIT_TARGET,
});

export interface IPendingAdminVideo {
  liveStreams?: any[],
  offline?: any[],
  schedule?: any[]
}

export const setPendingAdminChange = makeAction(
  SET_PENDING_ADMIN_CHANGE,
  (
    targetType: TargetTypes,
    pendingData: IPendingAdminVideo | any,
    clearTarget?: boolean,
  ) => ({
    payload: { clearTarget, pendingData, targetType },
  }),
);

export const setPendingPanelChange = makeAction(
  SET_PENDING_PANEL_CHANGE,
  (pageId: string, kind: string, panel: IPanel<{}>) => ({
    payload: { pageId, kind, panel },
  }),
);

export const resetAllPendingAdminChanges = makeAction(RESET_ALL_PENDING_ADMIN_CHANGES);

export const resetPendingAdminChange = makeAction(
  RESET_PENDING_ADMIN_CHANGE,
  (targetType: TargetTypes) => ({
    payload: { targetType },
  }),
);

export const setPreviewCardRenderer = makeAction(
  SET_PREVIEW_CARD_RENDERER,
  (cardRenderer: any) => ({
    payload: { cardRenderer },
  }),
);

export const setPreviewActiveTheme = makeAction(
  SET_PREVIEW_ACTIVE_THEME,
  (theme: ITheme | null) => ({
    payload: { theme },
  }),
);

export const setPreviewPanelRenderer = makeAction(
  SET_PREVIEW_PANEL_RENDERER,
  (panelRenderer: any) => ({
    payload: { panelRenderer },
  }),
);

export const publishPendingChanges = makeAction(
  PUBLISH_PENDING_CHANGES,
  ({
    pageId = null,
    ...payload
  }: IPublishPendingChangesPayload = {}) => ({
    payload: {
      pageId,
      ...payload,
    },
  }),
);

export const publishGateState = makeAction(
  PUBLISH_GATE_STATE,
  ({ updatedPage }: IPublishGatePayload) => ({ payload: { updatedPage } }),
);

export const publishPendingGateChanges = makeAction(PUBLISH_PENDING_GATE_CHANGES);
export const publishPendingPanelChanges = makeAction(PUBLISH_PENDING_PANEL_CHANGES);

export const createChannelOrPage = makeAction(
  CREATE_CHANNEL_OR_PAGE,
  ({ newChannelOrPage, path = '', updatedDefaultNavigation }: ICreateChannelOrPagePayload) => ({ payload: { newChannelOrPage, path, updatedDefaultNavigation } }),
);

export const createChannelOrPageSuccess = makeAction(CREATE_CHANNEL_OR_PAGE_SUCCESS);
export const createChannelOrPageError = makeAction(CREATE_CHANNEL_OR_PAGE_ERROR);

export const createPanel = makeAction(CREATE_PANEL, ({ kind, action, copyInformation }) => ({ payload: { kind, action, copyInformation } }));
export const createPanelSuccess =  makeAction(CREATE_PANEL_SUCCESS);
export const createPanelError = makeAction(CREATE_PANEL_ERROR);

export const deletePanel = makeAction(DELETE_PANEL, ({ id, kind, renderer }) => ({ payload: { id, kind, renderer } }));

export const setRegionRendererDraft = makeAction(
  SET_REGION_RENDERER_DRAFT,
  (id: string, renderer: any) => ({
    payload: { id, renderer },
  }),
);

export const clearRegionRendererDraft = makeAction(
  CLEAR_REGION_RENDERER_DRAFT,
  (id: string) => ({
    payload: { id },
  }),
);

export const clearPendingPageDoc = makeAction(CLEAR_PENDING_PAGE_DOC, (id) => ({
  payload: { id },
}));

export const clearPendingGateChange = makeAction(CLEAR_PENDING_GATE_CHANGE, (id) => ({
  payload: { id },
}));

export const clearPendingPanelChange = makeAction(CLEAR_PENDING_PANEL_CHANGE, (pageId: string) => ({
  payload: { pageId },
}));

// Actual writes
export const writeToCollectionLegacy = makeAction(
  WRITE_TO_COLLECTION,
  (doc: any) => ({
    payload: { doc },
  }),
);

export const archivePage = makeAction(
  ARCHIVE_PAGE,
  (id: string) => ({
    payload: { id },
  }),
);

export const writeLegacySuccess = makeAction(WRITE_LEGACY_SUCCESS);

export const updateRefreshKey = makeAction(UPDATE_REFRESH_KEY);

export const editCollectionItem = makeAction(
  EDIT_COLLECTION_ITEM,
  (type: any, id: any, renderer: any, archivedTimestamp: any, validate: any, deleteItem: any) => ({
    payload: {
      archivedTimestamp,
      id,
      renderer,
      type,
      validateCrossChannelObject: validate,
      isDelete: deleteItem,
    },
  }),
);

export const setPendingThemesDoc = makeAction(
  SET_PENDING_THEME_DOC,
  (id: string, doc: any) => ({
    payload: { doc, id },
  }),
);

export const setPendingPageDoc = makeAction(
  SET_PENDING_PAGE_DOC,
  (id: string, doc: any) => ({
    payload: { doc, id },
  }),
);

export const setRegionSaved = makeAction(
  SET_REGION_SAVED,
  (targetType: TargetTypes) => ({
    payload: { targetType },
  }),
);


export const clearPendingThemesDocs = makeAction(CLEAR_PENDING_THEMES_DOCS);

export const setMockPanelRenderer = makeAction(
  SET_MOCK_PANEL_RENDERER,
  (renderer: any) => ({
    payload: renderer,
  }),
);

export const setMockCardRenderer = makeAction(
  SET_MOCK_CARD_RENDERER,
  (renderer: any) => ({
    payload: renderer,
  }),
);

export const setMaestroLivestreamPreview = makeAction(
  SET_MAESTRO_LIVESTREAM_PREVIEW,
  (payload?: boolean | null) => ({
    payload,
  }),
);

export type ISetLivestreamPreviewAction = ReturnType<typeof setMaestroLivestreamPreview>;

export const goLiveWithMaestroLivestream = makeAction(MAESTRO_GO_LIVE);

export const goOfflineWithMaestroLivestream = makeAction(MAESTRO_GO_OFFLINE);

export const setDraftTheme = makeAction(
  SET_DRAFT_THEME,
  (payload: any) => ({
    payload,
  }),
);

export const saveSiteSettings = makeAction(
  SAVE_SITE_SETTINGS,
  (typeKey: SettingsTypes | TargetTypes, data: any) => ({
    payload: { data, typeKey },
  }),
);

export const setAccessControlFtue = makeAction(SET_ACCESS_CONTROL_FTUE);

export const confirmCrossChannelValidationModal = makeAction(CONFIRM_CROSS_CHANNEL_VALIDATION_MODAL);

export const saveQuest = makeAction(
  SAVE_QUEST,
  (questDoc: any) => ({
    payload: questDoc,
  }),
);

export const setCurrentSidebarPage = makeAction(
  SET_CURRENT_SIDEBAR_PAGE,
  (page: any) => ({
    payload: page,
  }),
);


export const setGateTitleChanges = makeAction(
  SET_GATE_TITLE_CHANGES,
  (id: string, styledTitle: string, field: 'titleRawData' | 'titleRawDataV2') => ({
    payload: { id, styledTitle, field },
  }),
);

export const setGateSubtitleChanges = makeAction(
  SET_GATE_SUBTITLE_CHANGES,
  (id: string, styledSubtitle: string, field: 'subtitleRawData' | 'subtitleRawDataV2') => ({
    payload: { id, styledSubtitle, field },
  }),
);

export const setGateDateAndTime = makeAction(
  SET_GATE_DATE_AND_TIME,
  (id, dateAndTime) => ({
    payload: { id, dateAndTime },
  }),
);

export const setGateNavigation = makeAction(
  SET_GATE_NAVIGATION,
  (id, navigation) => ({
    payload: { id, navigation },
  }),
);

export const setGateLogo = makeAction(
  SET_GATE_LOGO,
  (id, logoData) => ({
    payload: { id, logoData },
  }),
);

export const setGateBackground = makeAction(
  SET_GATE_BACKGROUND,
  (id, backgroundData) => ({
    payload: { id, backgroundData },
  }),
);

export const setGateKindInformation = makeAction(
  SET_GATE_KIND_INFORMATION,
  (id, kind, password, subscriptionsData, amazonBenefitData) => ({
    payload: { id, kind, password, subscriptionsData, amazonBenefitData },
  }),
);

export const setIsEditingGateLogo = makeAction(
  SET_IS_EDITING_GATE_LOGO,
  (isEditing: boolean) => ({
    payload: isEditing,
  }),
);

// ADMIN BAR NOTIFICATIONS

export const setAdminBarNotifications = makeAction(
  SET_ADMIN_BAR_NOTIFICATIONS,
  (payload: ISetAdminBarNotificationsPayload) => ({
    payload,
  }),
);

export const displayAdminBarNotificationsAlert = makeAction(
  DISPLAY_ADMIN_BAR_NOTIFICATIONS_ALERT,
  (payload: IGenericAdminBarNotificationPayload) => ({
    payload,
  }),
);

export const displayAdminBarNumericNotification = makeAction(
  DISPLAY_ADMIN_BAR_NUMERIC_NOTIFICATION,
  (payload: IDisplayAdminBarNumericNotificationPayload) => ({
    payload,
  }),
);

export const clearAdminBarNotification = makeAction(
  CLEAR_ADMIN_BAR_NOTIFICATION,
  (payload: IGenericAdminBarNotificationPayload) => ({
    payload,
  }),
);
